import { createRouter, createWebHistory } from "vue-router";

import HomePage from "@/views/HomePage";
import RegistrationPage from "@/views/RegistrationPage";
import LoginForm from "@/views/LoginForm";
import BuyTicket from "@/views/BuyTicket";
import CertificatesView from "@/views/CertificatesView";
import ReceiptView from "@/views/ReceiptView";
import PersonalAccount from "@/views/PersonalAccount";
import AquaparkEvent from "@/views/AquaparkEvent";
import AquaparkRule from "@/views/AquaparkRule";
import AboutPage from "@/views/AboutPage";
import AquaparkNews from "@/views/AquaparkNews";
import NewsItem from "@/views/NewsItem";
import ForgotPassword from "@/views/ForgotPassword";
import TicketComponent from "@/views/TicketComponent";
import ResetPassword from "@/views/ResetPassword";
import bonus from "@/views/bonus";
import basket from "@/views/basket";
import successPayment from "@/views/successPayment";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
  },
  {
    path: "/registration",
    name: "Регистрация",
    component: RegistrationPage,
  },
  {
    path: "/login",
    name: "Логин",
    component: LoginForm,
  },
  {
    path: "/buy-ticket",
    name: "Покупка билета",
    component: BuyTicket,
  },
  {
    path: "/personal-account",
    name: "Личный кабинет",
    component: PersonalAccount,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/events/:id",
    name: "event",
    component: AquaparkEvent,
  },
  {
    path: "/events/:id",
    name: "event",
    component: AquaparkEvent,
  },
  {
    path: "/rule/:name",
    name: "rule",
    component: AquaparkRule,
  },
  {
    path: "/about/:id",
    name: "about",
    component: AboutPage,
  },
  {
    path: "/news",
    name: "Новости",
    component: AquaparkNews,
  },
  // {
  //   path: "/receipt",
  //   name: "Receipt",
  //   component: ReceiptView,
  //   meta: {
  //     layout: "receipt",
  //   },
  // },
  {
    path: "/:slug",
    name: "news-item",
    component: NewsItem,
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    name: "Сброс пароля",
    component: ResetPassword,
  },
  {
    path: "/ticket",
    name: "Билет",
    component: TicketComponent,
  },
  {
    path: "/loyalty-program",
    name: "Bonus",
    component: bonus,
    // meta: {
    //   requiresAuth: true
    // }
  },
  {
    path: "/basket",
    name: "basket",
    component: basket,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/certificates",
    name: "certificates",
    component: CertificatesView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/thanks",
    name: "thanks",
    component: successPayment,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView({ behavior: "smooth" });
  },
});

router.beforeEach((to, from, next) => {
  const fromPage = from.name; // Получаем имя предыдущей страницы
  const isNavigatedFromOtherPage = fromPage === 'basket';
  document.title = `${process.env.VUE_APP_TITLE} - ${to.name}`;
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("access_token")) {
      console.log("asasxs");
      if (to.name === 'thanks' && isNavigatedFromOtherPage) {
        next();
      } else if (to.name === 'thanks' && !isNavigatedFromOtherPage) {
        next({ name: "Home" });
      } else {
        next()
      }
    } else {
      console.log("dddd");
      next({ name: "Логин" });
      return;
    }
  } else {
    next();
  }
});

export default router;
