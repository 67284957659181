<template>
  <div class="registration">
    <div class="registration__container _container">
      <h1 class="registration__title main-title _blue">
        {{ $t("login.regist") }}
      </h1>
      <div class="registration__body">
        <Form
          class="registration__form"
          @submit="getRegister"
          :validation-schema="schema"
          v-slot="{ errors }"
        >
          <div class="registration__item">
            <label for="fullName">{{ $t("login.nameSurname") }}</label>
            <Field
              type="text"
              :class="{ _error: errors.hasOwnProperty('name') }"
              name="name"
              class="registration__input main-input"
              id="fullName"
              v-model="name"
            />
            <transition name="fade">
              <span class="error-text" v-if="errors.hasOwnProperty('name')">{{
                errors.name
              }}</span>
            </transition>
          </div>
          <div class="registration__item">
            <label for="phone">{{ $t("inputs.phoneNumber") }}</label>
            <Field
              :class="{ _error: errors.hasOwnProperty('phone') }"
              type="text"
              class="registration__input main-input"
              id="phone"
              v-mask="'+7 (###) ###-##-##'"
              v-model="phone_number"
              name="phone"
            />
            <transition name="fade">
              <span class="error-text" v-if="errors.hasOwnProperty('phone')">{{
                errors.phone
              }}</span>
            </transition>
          </div>
          <div class="registration__item">
            <label for="email">Email</label>
            <Field
              name="email"
              type="email"
              :class="{ _error: errors.hasOwnProperty('email') }"
              class="registration__input main-input"
              id="email"
              v-model="email"
            />
            <transition name="fade">
              <span class="error-text" v-if="errors.hasOwnProperty('email')">{{
                errors.email
              }}</span>
            </transition>
          </div>

          <div class="registration__item">
            <label for="password">{{ $t("login.password") }}</label>
            <div
              class="registration__password"
              :class="{ _error: errors.hasOwnProperty('password') }"
            >
              <Field
                name="password"
                :type="showInput ? 'text' : 'password'"
                class="registration__input main-input"
                id="password"
                v-model="password"
                ref="password"
              />
              <button type="button" @click="showInput = !showInput">
                <span><img src="@/assets/icons/form/eye.svg" alt="" /></span>
              </button>
            </div>
            <transition name="fade">
              <span
                class="error-text"
                v-if="errors.hasOwnProperty('password')"
                >{{ errors.password }}</span
              >
            </transition>
          </div>

          <div class="registration__item">
            <label for="passwordRepeat">{{ $t("login.repeatPassword") }}</label>
            <div
              class="registration__password"
              :class="{ _error: errors.hasOwnProperty('check_password') }"
            >
              <Field
                :type="showSecondInput ? 'text' : 'password'"
                class="registration__input main-input"
                id="passwordRepeat"
                v-model="check_password"
                name="passwordConfirm"
              />
              <button type="button" @click="showSecondInput = !showSecondInput">
                <span><img src="@/assets/icons/form/eye.svg" alt="" /></span>
              </button>
            </div>
            <transition name="fade">
              <span
                class="error-text"
                v-if="errors.hasOwnProperty('passwordConfirm')"
                >{{ errors.passwordConfirm }}</span
              >
            </transition>
          </div>
          <div class="registration__item checkbox-item">
            <Field
              name="acceptTerms"
              type="checkbox"
              class="checkbox-item__input"
              id="remember"
              :value="true"
              v-model="acceptTerms"
            />
            <span
              class="checkbox-item__icon"
              :style="
                errors.hasOwnProperty('acceptTerms') && !acceptTerms
                  ? 'border: 1px solid red;'
                  : 'border: 1px solid #256ed8;'
              "
            ></span>
            <label for="remember" v-html="$t('login.rulesReg')"></label>
            <!-- <transition name="fade">
              <span
                class="error-text"
                v-if="errors.hasOwnProperty('acceptTerms')"
                >{{ errors.acceptTerms }}</span
              >
            </transition> -->
          </div>
          <div class="registration__item">
            <button class="registration__btn blue-button" type="submit">
              {{ $t("login.register") }}
            </button>
            <transition name="fade">
              <span class="error-message" v-if="showError"
                >Пользователь уже зарегистрирован</span
              >
            </transition>
          </div>
        </Form>

        <div class="registration__bottom">
          <span class="registration__text"
            >{{ $t("login.alreadyAccount") }}
          </span>
          <router-link to="/login" class="registration__link">{{
            $t("auth")
          }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { Form, Field } from "vee-validate";
import { mapActions, mapMutations } from "vuex";
import * as Yup from "yup";

export default {
  data() {
    const schema = Yup.object().shape({
      name: Yup.string()
        .required(this.$t("errors.required"))
        .label("Name")
        .nullable(),
      phone: Yup.string()
        .min(18, this.$t("errors.minLenght", { value: 18 }))
        .required(this.$t("errors.required"))
        .label("Name")
        .nullable(),
      email: Yup.string()
        .email(this.$t("errors.email"))
        .required(this.$t("errors.required")),
      password: Yup.string()
        .min(5, this.$t("errors.minLenghtPassword", { value: 5 }))
        .required(this.$t("errors.required")),
      passwordConfirm: Yup.string()
        .required(this.$t("errors.required"))
        .oneOf([Yup.ref("password"), null], this.$t("errors.mustMatch"))
        .label("Password")
        .nullable(),
      acceptTerms: Yup.string().required(this.$t("errors.personal")),
    });
    return {
      name: null,
      phone_number: null,
      email: null,
      password: null,
      check_password: null,
      schema,
      showInput: false,
      showSecondInput: false,
      showError: false,
    };
  },
  components: {
    Field,
    Form,
  },
  methods: {
    ...mapMutations(["SET_USER"]),
    ...mapActions(["checkAuth"]),
    getRegister() {
      axios
        .post("https://admin.hawaii.kz/api/V1/registration", {
          name: this.name,
          phone_number: this.phone_number,
          email: this.email,
          password: this.password,
          check_password: this.check_password,
        })
        .then((res) => {
          if (res.status === 201) {
            if (localStorage.getItem("payment") == "true") {
              this.$router.push("/buy-ticket");
            } else {
              this.$router.push("/personal-account");
            }
            // this.SET_USER(res.data.user);
            localStorage.setItem("access_token", res.data.accessToken);
            this.checkAuth();
          }
        })
        .catch((error) => {
          this.showError = true;
          console.error("There was an error!", error);
        });
    },
  },
};
</script>

<style lang="scss">
.registration {
  padding: 50px 0 70px 0;
  @media (max-width: 991.98px) {
    padding: 41px 0;
  }
  @media (max-width: $mobileSmall + px) {
    padding: 32px 0;
  }

  &__title {
    margin: 0 0 100px 0;
    @media (max-width: $tablet + px) {
      margin: 0 0 55px 0;
    }
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 71px 0;
    }
  }

  &__body {
    margin: 0 auto;
    @media (min-width: $tablet + px) {
      max-width: 650px;
      background: #ffffff;
      box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.2);
      border-radius: 60px;
      padding: 50px 40px;
    }
  }

  &__item {
    position: relative;
    label {
      display: block;
      margin: 0 0 8px 0;
      font-weight: 400;
      font-size: 18px;
      line-height: 136.7%;
      letter-spacing: 0.02em;
      color: #256ed8;
      @media (max-width: $tablet + px) {
        font-size: 17px;
      }
      @media (max-width: $mobileSmall + px) {
        font-size: 14px;
        margin: 0 0 5px 0;
      }
    }

    &:not(:last-child) {
      margin: 0 0 35px 0;
      @media (max-width: $tablet + px) {
        margin: 0 0 27px 0;
      }
      @media (max-width: $mobileSmall + px) {
        margin: 0 0 24px 0;
      }
    }
  }

  &__input {
    width: 100%;
  }

  &__btn {
    margin: 15px 0 100px 0;
    @media (max-width: $tablet + px) {
      margin: 10px 0 80px 0;
    }
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 48px 0;
    }
  }

  &__link {
    font-weight: 600;
    font-size: 20px;
    line-height: 137.5%;
    letter-spacing: 0.02em;
    color: #256ed8;
    @media (max-width: $tablet + px) {
      font-size: 17px;
    }
    @media (max-width: $mobileSmall + px) {
      font-size: 14px;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 20px;
    line-height: 134.61%;
    letter-spacing: 0.02em;
    color: #256ed8;
    @media (max-width: $tablet + px) {
      font-size: 18px;
    }
    @media (max-width: $mobileSmall + px) {
      font-size: 15px;
    }
  }

  &__password {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(37, 110, 216, 0.6);
    padding: 0 24px;
    border-radius: 15px;

    input {
      border: none;
      padding: 0;
    }

    span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      @media (max-width: $tablet + px) {
        width: 25px;
        height: 25px;
      }
      @media (max-width: $mobileSmall + px) {
        width: 20px;
        height: 20px;
      }

      img {
        width: 24.03px;
        height: 17.5px;
        @media (max-width: $tablet + px) {
          width: 20px;
          height: 14.5px;
        }
        @media (max-width: $mobileSmall + px) {
          width: 16.02px;
          height: 11.67px;
        }
      }
    }
  }
  .checkbox-item {
    display: flex;
    align-items: center;
    &__input {
      position: absolute;
      opacity: 0;
      z-index: 1;
      width: 24px;
      height: 24px;
      @media (max-width: $tablet + px) {
        width: 22px;
        height: 22px;
      }
      @media (max-width: $mobileSmall + px) {
        width: 20px;
        height: 20px;
      }

      &:checked ~ .checkbox-item__icon {
        &::after {
          opacity: 1;
        }
      }
    }

    &__icon {
      background: #ffffff;
      border: 1px solid #256ed8;
      border-radius: 4px;
      width: 24px;
      height: 24px;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      @media (max-width: $tablet + px) {
        width: 22px;
        height: 22px;
      }
      @media (max-width: $mobileSmall + px) {
        width: 20px;
        height: 20px;
      }

      &::after {
        content: url("@/assets/icons/form/checkmark.svg");
        transition: opacity 0.25s ease 0s;
        opacity: 0;
      }
    }

    label {
      display: inline-block;
      font-weight: 400;
      font-size: 18px;
      letter-spacing: 0.02em;
      color: #256ed8;
      margin: 0 0 0 12px;
      cursor: pointer;
      @media (max-width: $tablet + px) {
        font-size: 16px;
        margin: 0 0 0 10px;
      }
      @media (max-width: $mobileSmall + px) {
        font-size: 14px;
        margin: 0 0 0 8px;
      }
    }
    span {
      text-decoration: underline;
    }
  }
  &__bottom {
    text-align: center;
  }
  .error-message {
    top: 50px;
    @media (max-width: $mobileSmall + px) {
      top: 23px;
    }
  }
}
</style>
